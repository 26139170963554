import brand from '../assets/app_logo.png';

export const AppNavbar = (props) => {
    return (
        <nav className="navbar navbar-expand-lg  nav-bar">
            <div className="container-fluid">
                <div className={'row'}>
                    <div className={'col-auto'}>
                        <img src={brand} width={32} height={32} alt={'Daily Saga App Logo'}/>
                    </div>
                </div>
                <div className={'col nav-bar-brand'}>
                    Daily Saga
                </div>

            </div>
        </nav>
    );
}
