import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../stylesheets/styles.css';
import {Outlet} from "react-router-dom";
import {AppNavbar} from "./AppNavbar";

export const Layout = (props) => {
    return (
        <div>
            <AppNavbar/>
            <div className={'container'}>
                <Outlet/>
            </div>
        </div>
    );
}
