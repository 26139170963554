import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Layout} from "./components/layout";
import {Home} from "./components/home"

function App() {
    return (
        <Routes element={<Layout/>} path={'/home'}>
            <Route element={<Home/>} path={'/home/about'}/>
        </Routes>
    );
}

export default App;
