import screenshot_01 from '../assets/rebranding_screenshots_1.png';
import screenshot_02 from '../assets/rebranding_screenshots_2.png';
import screenshot_03 from '../assets/rebranding_screenshots_3.png';
import screenshot_04 from '../assets/rebranding_screenshots_4.png';
import screenshot_05 from '../assets/rebranding_screenshots_5.png';
import screenshot_06 from '../assets/rebranding_screenshots_6.png';
import screenshot_07 from '../assets/rebranding_screenshots_7.png';
import screenshot_08 from '../assets/rebranding_screenshots_8.png';

export const Home = (props) => {
    return (
        <div className={'shadow'}>
            <div id="carousel"
                 className="carousel slide mt-5 bg-dark pt-3 pb-3 border-bottom-0 border carousel-container"
                 data-bs-ride={'carousel'}
            >
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={screenshot_01}
                             className="d-block align-self-center carousel-item-image"
                             alt="Build habits, stay organized with repeating quests"
                        />
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_02} className="d-block carousel-item-image"
                             alt="Create one time quests for events you don't want to forget about"/>
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_03} className="d-block carousel-item-image"
                             alt="Attributes provide an overall measurement of how well you are doing in different areas of your life. Create custom attributes that best fit you!"/>
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_04} className="d-block carousel-item-image"
                             alt="View upcoming quests and receive reminders when your quests are due"/>
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_05} className="d-block carousel-item-image"
                             alt="Receive experience, levels, and coins when you complete your quests!"/>
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_06} className="d-block carousel-item-image"
                             alt="Earn coins when certain milestones are met throughout your journey"/>
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_07} className="d-block carousel-item-image"
                             alt="Spend your coins on icons at the merchant. Rewards come in 5 different qualities: Common, Uncommon, Rare, Epic, and Legendary!"/>
                    </div>
                    <div className="carousel-item">
                        <img src={screenshot_08} className="d-block carousel-item-image"
                             alt="Add the widget to your device and always be informed when quests are due"/>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button"
                        data-bs-target="#carousel"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button"
                        data-bs-target="#carousel"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className={'row mb-5'}>
                <div className={'col'}>
                    <ul className={'list-group description-group '}>
                        <li className={'list-group-item p-3 bg-dark text-light border-top-0 '}>
                            <h1 className={'fw-bold'}>Daily Saga</h1>
                        </li>
                        <li className={'list-group-item'}>
                            <ul className={'list-group list-group-flush'}>
                                <li className={'list-group-item'}>
                                    Gamify your life by turning your schedule into quests. Quests can be anything you
                                    want (cooking, programming, doing laundry, feed pets, etc.).
                                    <br/>
                                    <br/>
                                    Receive reminders when quests on your schedule are due. Converting your schedule
                                    into quests, can help you have more enjoyment while reaching your goals and staying
                                    productive!
                                </li>
                                <li className={'list-group-item'}>
                                    <h3 className={'fw-bold'}>Quests</h3>
                                    Quests are tasks or events on your schedule that you setup. When a quest is due, you
                                    will receive a reminder! Similar to an RPG, completing quests will award you with
                                    coins, experience points, and levels. As a quest levels up, levels will become more
                                    difficult to attain, but the rewards increase. Setup quests with yearly, monthly,
                                    weekly, daily, or one time frequencies!
                                </li>
                                <li className={'list-group-item'}>
                                    <h3 className={'fw-bold'}>Attributes (optional)</h3>
                                    Just like a character in an RPG, attributes act as stats that represent you!
                                    Attributes provide an additional measurement of how productive you are being in
                                    different areas of your life.
                                    <br/>
                                    An attribute can be assigned to any quest. Completing quests on your schedule will
                                    also reward experience to your attributes. You can create custom attributes such as
                                    home care, losing weight, animal handling, or anything you can think of! There are 7
                                    default attributes: intelligence, wisdom, charisma, strength, agility, perception,
                                    and constitution. Assigning a quest to an attribute gives you another form of
                                    progression. Attributes can be assigned themes, icons, and colors!
                                </li>
                                <li className={'list-group-item'}>
                                    <h3 className={'fw-bold'}>Achievements</h3>
                                    Achievements are milestones that are awarded automatically throughout your journey!
                                    Milestones include reaching certain levels, completing quests a certain a amount of
                                    times, or completing different actions.
                                </li>
                                <li className={'list-group-item'}>
                                    <h3 className={'fw-bold'}>The Merchant</h3>
                                    Completing your quests awards you coins! Spend your earned coins on rewards at the
                                    merchant. Buy icons to further customize your attributes! Icons can be 1 of 5
                                    different qualities (common, uncommon, rare, epic, legendary). The icons that are
                                    available will change daily, and there are over 7000 possible icons. All coins are
                                    earned in the app, and there are no in-app purchases
                                </li>
                                <li className={'list-group-item'}>
                                    <h3 className={'fw-bold'}>Due Quests Widget (optional)</h3>
                                    Add the widget to your phone to easily see which quests are currently due, this helps you keep track of your schedule, and staying productive!
                                </li>
                                <li className={'list-group-item'}>
                                    <h3 className={'fw-bold'}>Features</h3>
                                    <ul>
                                        <li>
                                            Build habits by creating quests with different frequencies (yearly, monthly, weekly, daily, or one time)
                                        </li>
                                        <li>
                                            Stay organized by assigning different time(s) a quest is due. Quests can have multiple reminder dates/times
                                        </li>
                                        <li>
                                            Stay organized by receiving reminders when your quests are due
                                        </li>
                                        <li>
                                            Assign a difficulty to each quest. Choose from easy, medium, and hard. Easy provides the most experience gains, but the least amount of coins.
                                        </li>
                                        <li>
                                            Decay: enable quest and/or attribute decay. Quests and attributes will lose experience the following day if not completed. When enabled this will more accurately determine your experience and level in a quest or attribute
                                        </li>
                                        <li>
                                            Silence: you can decide at anytime to silence a weekly/daily quest and stop it from sending you reminders. While enabled quests will not be considered due, and will not decay
                                        </li>
                                        <li>
                                            For maximum productivity, add the Quests Due Widget to your device to easily see which quests are currently due!
                                        </li>
                                        <li>
                                            Measure your overall productivity by creating custom attributes (home care, losing weight, animal handling, or anything you want)
                                        </li>
                                        <li>
                                            Assign different themes, colors and icons to customize your attributes (assigning a theme, icon, or color to an attribute, will also change the appearance of quests that are currently assigned to it)
                                        </li>
                                        <li>
                                            Receive coins, experience, and levels when completing quests!
                                        </li>
                                        <li>
                                            Obtain achievements when certain milestones are met! Achievements also reward coins!
                                        </li>
                                        <li>
                                            Spend your earned coins at the merchant on different customization options!
                                        </li>
                                        <li>
                                            Collect all the icons (there are over 7000+ icons to collect). Icons come in different qualities (common, uncommon, rare, epic, legendary)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className={'list-group-item text-center'}>
                            <a href='https://play.google.com/store/apps/details?id=com.dailysaga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                                className={'img img-fluid'} alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                            <div className={'small'}>
                                Google Play and the Google Play logo are trademarks of Google LLC.
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    )
}
